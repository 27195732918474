import { graphql } from "gatsby"
import React from "react"
import OurWorkPage from '../components/pages/OurWork'

const lang = "en"

const OurWork = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_en,
    titleSeo: seoDataJson.title_en,
    description: seoDataJson.description_en,
    alternateLanguage: seoDataJson.alternateLanguage_en,
    alternateUrl: seoDataJson.alternateUrl_en,
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "OUR-WORK"
  })

  return (
    <OurWorkPage
      lang={lang}
      data={data}
      seoData={seoData}
      urls={resultUrls}
    />
  )
}

export const dataOurWork = graphql`
  query dataOurWork {
    allFile(filter: { ext: { regex: "/jpg|png/" } }) {
      nodes {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        extension
        publicURL
      }
    }
    logos: allFile(filter: { extension: { eq: "svg" } }) {
      nodes {
        extension
        publicURL
      }
    }
    allProjectsJson(filter: { lang: { eq: "en" } }) {
      edges {
        node {
          name
          fileName
          description
          sideChange
          logo
          lang
          sendTo
        }
      }
    }
    seoDataJson(name: { eq: "our-work" }) {
      url_en
      title_en
      description_en
      alternateLanguage_en
      alternateUrl_en
    }
  }
`

export default OurWork
